import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <h2>
      <Link
        to="/"
        style={{
          color: 'black',
          fontWeight: 200,
          textDecoration: `none`,
        }}
      >
        {siteTitle}
      </Link>
    </h2>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteMetadata: ``,
}

export default Header
