import { StaticQuery, Link, graphql } from "gatsby"
import React from "react"


const Navigation = () => (
  <StaticQuery
    query={graphql`
        query {
          links: allMarkdownRemark(
              sort: { order: ASC, fields: [frontmatter___order] }
              filter: { frontmatter: { title:{nin:["Seitenleiste"]}}}
              limit: 1000
            ) {
            edges {
              node {
                frontmatter {
                  path
                  title
                }
              }
            }
          }
        }
      `}
    render={data => (
      <div class="nav">
        {data.links.edges.map(({ node }) => {
          return <Link
            to={node.frontmatter.path || '/'}
            key={node.frontmatter.title}
            style={{
              color: 'black',
              fontWeight: 200,
              textDecoration: `none`,
            }}
          >{node.frontmatter.title}
          </Link>
        })}
      </div>)}
  />
)

export default Navigation;
