import React from "react"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

export default function TitleImage ({
  imageData, // this prop will be injected by the GraphQL query below.
}) {
  return (
      <Img 
        fluid={imageData.childImageSharp.fluid} 
        objectFit="cover"
        objectPosition="50% 50%"
        alt="Titelbild"        
      />
  );
  }
